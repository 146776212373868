<template>
  <el-dialog
    append-to-body
    width="500px"
    title="审核通过"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <!-- <el-form-item label="折扣率" prop="discount_type">
          <el-radio-group v-model="form.data.discount_type" v-removeAriaHidden>
            <el-radio :label="1">按商品分类</el-radio>
            <el-radio :label="2">按商品名称</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="结算方式" prop="order_type">
          <el-radio-group v-model="form.data.order_type" v-removeAriaHidden>
            <el-radio :label="2">线上支付</el-radio>
            <el-radio :label="1">线下结算</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="售价小数" prop="point" >
          <el-select v-model="form.data.point">
            <el-option label="无小数" :value="0"></el-option>
            <el-option label="1位小数" :value="1"></el-option>
            <el-option label="2位小数" :value="2"></el-option>
            <el-option label="3位小数" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="超出规则" prop="point_rule">
          <el-radio-group v-model="form.data.point_rule" v-removeAriaHidden>
            <el-radio :label="1">四舍五入</el-radio>
            <el-radio :label="2">直接抹去</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="配送方式" prop="distr_text" >
          <el-input
            size="mini"
            v-model.trim="form.data.distr_text"
            placeholder="请输入"
            clearable
            style="width:100%;"
          ></el-input>
        </el-form-item>
        <el-form-item label="起送金额" prop="surpass_amount" >
          <el-input
            size="mini"
            v-model.trim="form.data.surpass_amount"
            placeholder="请输入"
            clearable
            style="width:100%;"
            @input="form.data.surpass_amount=form.data.surpass_amount.match(/\d+(\.\d{0,2})?/) ? form.data.surpass_amount.match(/\d+(\.\d{0,2})?/)[0] : ''"
          ><span slot="suffix">元</span></el-input>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'AccountEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            status: 1,
            // discount_type: '',
            order_type: '',
            point: '',
            point_rule: '',
            distr_text: '今日下单次日配送，特殊情况请联系平台',
            surpass_amount: '',
          },
          rules: {
            // discount_type: [{ required: true, message:'必填项', trigger: 'change'}],
            order_type: [{ required: true, message:'必填项', trigger: 'change'}],
            point: [{ required: true, message:'必填项', trigger: 'change'}],
            point_rule: [{ required: true, message:'必填项', trigger: 'change'}],
            distr_text: [{ required: true, message:'必填项', trigger: 'change'}],
            surpass_amount: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
      }
    },
    methods: {
      getDetail(row) {
        if(!!row.id) {
          this.isChange = true
          common.deepClone(this.form.data, row)
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {...this.form.data};
            this.form.loading = true;
            this.$http.post('/admin/check/operation', _params).then(res => {
              if(res.code === 1) {
                this.$message.success('认证完成，请在客户列表设置折扣体系');
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
