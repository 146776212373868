<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">待审核</el-radio-button>
      <el-radio-button :label="2">已通过</el-radio-button>
      <el-radio-button :label="3">已拒绝</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="商户名称" prop="merchant_name">
          <el-input
            size="mini"
            v-model.trim="table.params.merchant_name"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="餐饮类型" prop="merchant_type">
         <el-select v-model="table.params.merchant_type" @click="onSearch">
            <el-option label="中餐厅" :value="1"></el-option>
            <el-option label="西餐厅" :value="2"></el-option>
            <el-option label="火锅店" :value="3"></el-option>
            <el-option label="早点/粉面店" :value="4"></el-option>
            <el-option label="点心/甜点" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            size="mini"
            v-model.trim="table.params.phone"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <VTable
      has-pagionation
      :field="toStr({1:field,2:field2,3:field3},table.params.status)"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      :showIndex="true"
      :updateKey="updateKey"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:merchant_name="{row}">
        <div style="display:flex;align-items: center;">
          <el-image
            v-if="!!row.image && row.image.length>0"
            style="width: 40px; height: 40px"
            :src="row.image[0]"
            fit="cover"
            :preview-src-list="row.image"
          ></el-image>
          <span v-else></span>
          <span style="margin-left: 10px;">{{ row.merchant_name }}</span>
        </div>
      </template>
      <template v-slot:address="{row}">
        <span>{{row.address}}{{row.sup_address}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">通过</el-button>
        <el-button type="text" @click="handleReject(row)">拒绝</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit"  @refresh="getTable"></edit>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import { mixinTable } from '@/mixins/table.js'

export default {
  name: 'SpecialIndex',
  components: {
    VTable,
    Edit
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      loading: false,
      field: [
        { name: "merchant_name", label: "商户名称",hidden: false },
        { name: "type_name", label: "餐饮类型",width:'140',hidden: false },
        { name: "name", label: "联系人", width:'120', hidden: false},
        { name: "phone", label: "联系电话",width:'140', hidden: false},
        { name: "address", label: "联系地址", showTooltip: true, hidden: false},
        { name: "create_time", label: "提交时间",width:'140',hidden: false},
        { name: "action", label: "操作",width: "120", fixed:"right", hidden: false }
      ],
      field2: [
        { name: "merchant_name", label: "商户名称",hidden: false },
        { name: "type_name", label: "餐饮类型",width:'140',hidden: false },
        { name: "name", label: "联系人", width:'120', hidden: false},
        { name: "phone", label: "联系电话", width:'140', hidden: false},
        { name: "address", label: "联系地址", showTooltip: true, hidden: false},
        { name: "create_time", label: "提交时间",width:'140',hidden: false},
        { name: "check_time", label: "审核时间",width:'140',hidden: false},
      ],
      field3: [
        { name: "merchant_name", label: "商户名称",hidden: false },
        { name: "type_name", label: "餐饮类型",hidden: false },
        { name: "name", label: "联系人", width:'120', hidden: false},
        { name: "phone", label: "联系电话",width:'150', hidden: false},
        { name: "address", label: "联系地址", showTooltip: true, hidden: false},
        { name: "create_time", label: "提交时间",hidden: false},
        { name: "check_time", label: "审核时间",hidden: false},
        { name: "reason", label: "拒绝原因",hidden: false},
      ],
      multipleSelection:[],
      table: {
        loading: false,
        params: {
          merchant_name: '',
          merchant_type: '',
          phone: '',
          status:1,
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      updateKey:new Date()+'',
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      this.table.loading = true;
      this.$http.get('/admin/check/list', {params:_params}).then(res => {
        if(res.code === 1) {
          this.updateKey = new Date()+'';
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 编辑
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 拒绝
    handleReject(row) {
      this.$prompt('请输入拒绝原因', '拒绝', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder:"请输入（100字以内）",
        inputPattern:  /^.+$/,
        inputErrorMessage: '请输入拒绝原因',
        inputType:'textarea'
      }).then(({ value }) => {
        this.$http.post('/admin/check/operation', {id:row.id,status:2,reason:value}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功！')
            this.getTable();
          }
        })
      }).catch(() => {});
    },
  }
}
</script>